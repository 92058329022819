.video-hero {
  position: relative;

  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: map-get($colors, dark);
    overflow: hidden;
    z-index: -20;
  }

  &__video,
  &__poster img {
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    max-height:2000%;
    max-width: 2000%;
    z-index: -19;
  }
}
