@keyframes animateHidden {
  0% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes animateHiddenPhoto {
  0% { margin-top: -200%; }
  100% { margin-top: -200%;  }
}

@keyframes animateShowPhoto {
  0% { margin-top: -200%; }
  100% { margin-top: 0; }
}


@keyframes animateHeader {
  0% { opacity: 0; transform: translateY(-1rem); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes animateTitle {
  0% { opacity: 0; transform: translateY(0.6rem); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes animateSeparator {
  0% { transform: scaleX(0); opacity: 0; background: darken(map-get($colors, warning), 0) }
  3% { opacity: 1; background: darken(map-get($colors, warning), 20%); }
  6% {  transform: scaleX(1); }
  85% { background: darken(map-get($colors, warning), 20%); }
  100% { background: darken(map-get($colors, warning), 15%); }
}

@keyframes animateDesc {
  0% { opacity: 0; transform: translateY(0.3rem); }
  100% { opacity: 1; transform: translateY(0); }
}


@keyframes animateButton {
  0% { opacity: 0; transform: scale(.7); }
  70% { transform: scale(1); }
  100% { opacity: 1; }
}

@keyframes animateSocialIcon {
  0% { opacity: 0; transform: translateY(1.8rem); }
  80% { transform: translateY(-0.3rem); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes animatePhoto {
  0% { opacity: 0; transform: scale(1.1); }
  100% { opacity: 1;  }
}

@keyframes animateFromBottom {
  0% { opacity: 0; transform: translateY(2rem); }
  100% { opacity: 1; transform: translateY(0); }
}

.hello-world {
  color: #fff;

  @media (max-width: map-get($breakpoints,lg)-1) {
    padding: 4vw;
    display: block;
    height: auto;
  }

  .container {
    @media (max-width: map-get($breakpoints, lg)-1) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__bg {
    z-index: 10;
  }

  &__margin {
    min-height: 2rem;
  }

  &--transparent {
    background: rgba(#000000, 0.6);
    transition: background ease-out 1s; /* 1s */
  }

  h1, h2, p {
    text-shadow: .05rem .05rem 0 rgba(#000, .8);
    cursor: default;
  }

  .video-hero__container {
    position: fixed;
  }

  .video-hero__video {
    transition: opacity ease-out 1s;
  }

  .video-hero__poster {
    opacity: 1;
    transition: opacity ease-out 1s;

    &--hidden {
      opacity: 0;
    }
  }

  .video-hero__shadow {
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__my-name {
    font-weight: 300;
  }

  &__my-title {
    color: rgba(#fff, 0.5);
    text-shadow: none !important;
    font-family: "Montserrat", "Quicksand", sans-serif;
    font-weight: 500;
  }

  .separator {
    background: darken(map-get($colors, warning), 15%);
    width: 4rem;
  }

  &__my-desc {
    margin-bottom: .8rem;
  }

  &__contact-me {
    letter-spacing: 0.14rem;
    margin-bottom: 0.55rem;
    box-shadow: .05rem .05rem 0 0 rgba(#000, .8);
    position: relative;
    left: -0.1rem;
    font-weight: 500;
    transition: transform ease-in .5s;

    &:hover {
      transform: scale(1.05);
      transition: transform ease-out 0.2s;
    }
  }

  &__my-photo {
    background-image: url('../img/pawel-zentala-scaled.jpg');
    box-shadow: .05rem .05rem 0 0 rgba(#000, .8);
    transition: transform 1s;
    shape-outside: circle();

    @media (max-width: map-get($breakpoints, lg)-1) {
      width: 30vw;
    }

    @media (max-width: map-get($breakpoints, md)-1) {
      width: 45vw;
    }

    @media (max-width: map-get($breakpoints, sm)-1) {
      width: 60vw;
    }

    @media (min-width: map-get($breakpoints, lg)) {
      &:hover {
        transition: transform .3s;
        transform: scale(1.1);
      }
    }

    &--small {
      width: 26vw;
      float: right;
      margin: 0 0 1rem 1rem;
    }
  }

  @media (min-width: map-get($breakpoints, lg)) {
    &__my-name    { animation: animateHidden 0.1s linear 0s, animateHeader 0.5s ease-in 0s; }
    &__my-title   { animation: animateHidden 0.2s linear 0s, animateTitle 0.5s ease-in 0.2s; }
    .separator    { animation: animateHidden 0.4s linear 0s, animateSeparator 0.5s ease-out 0.4s; transform-origin: top left; }
    &__my-desc    { animation: animateHidden 0.6s linear 0s, animateDesc 1s ease-in 0.6s; }
    &__contact-me { animation: animateHidden 1.0s linear 0s, animateButton 0.5s ease-in 1s; }

    .social-media {
      li:nth-child(1) {animation: animateHidden 1.1s linear 0s, animateSocialIcon 1s ease-in 1.1s;}
      li:nth-child(2) {animation: animateHidden 1.2s linear 0s, animateSocialIcon 1s ease-in 1.2s;}
      li:nth-child(3) {animation: animateHidden 1.3s linear 0s, animateSocialIcon 1s ease-in 1.3s;}
      li:nth-child(4) {animation: animateHidden 1.4s linear 0s, animateSocialIcon 1s ease-in 1.4s;}
    }
  }

}
