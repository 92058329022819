html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  font-family: Montserrat, Ubuntu, Verdana;
  color: map-get($colors, white);
  //font-size: 1.3vw;

  @each $breakpoint-name, $size in $font-sizes {
    @if $breakpoint-name == 'xs' { font-size: $size; }
    @else { @media (min-width: map-get($breakpoints, $breakpoint-name)) { font-size: $size; } }
  }

}

body,
button,
input,
select,
textarea {
  font-family:
    Montserrat,
    Ubuntu,
    Cantarell,
    BlinkMacSystemFont,
    -apple-system,
    "Segoe UI",
    Roboto,
    Oxygen,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;
}

a {
  text-decoration: none;
  color: map-get($colors, warning);
  &:hover {
    color: darken(map-get($colors, warning), 15%);
  }
}

