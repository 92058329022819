@each $name, $color in $colors {

  /** Text Colors */
  .is-#{$name} {
    color: $color;
  }

  /** Background color */
  .is-bg-#{$name} {
    background: $color;
  }

}

.is-half-transparent {
  opacity: 0.6;
}
