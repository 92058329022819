$colors: (
  text: #000,
  background: #fff,

  white: #fff,
  light: #f5f5f5,
  dark: #363636,
  black: #0a0a0a,

  primary: #00d1b2,
  success: #23d160,
  info: #209cee,
  warning: #ffdd57,
  danger: #ff3860
) !default;


$breakpoints: (
  xl: 1600px,
  lg: 1200px,
  md: 900px,
  sm: 600px,
  xs: 0px
) !default;

$font-sizes: (
  xs: 4.2vw, //4.4
  sm: 3vw,
  md: 2.2vw,
  lg: 1.4vw,
  xl: 1.3vw
) !default;

// todo for buttons etc, md is base, and we have bigger and smaller
// we can also use it for texts
$scales:  (
  xl: 1.4,
  lg: 1.2,
  md: 1,
  sm: 0.8,
  xs: 0.6
) !default;

$social-media: (
  font-size: 2rem,
  default-color: #fff
) !default;

$text-color: #000;

